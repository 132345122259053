<template>
  <DetailTemplate :custom-class="'ticket-detail detail-page'">
    <template v-slot:header-title>
      <div class="d-flex align-center flex-wrap" v-if="pageLoaded">
        <span
          class="color-custom-blue font-weight-700 font-size-20 text-truncate mr-2"
          style=""
          >{{ detail.title }}
        </span>

        <v-chip
          label
          color="chip-custom-blue"
          outlined
          class="text-white p-3 mr-2"
          style="font-size: 21px !important; font-weight: 700"
          >{{ detail.barcode }}</v-chip
        >
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              style="height: 25px"
              v-bind="attrs"
              v-on="on"
              :color="priorityColor(detail.priority)"
              outlined
              label
              small
              class="mr-1"
            >
              <span class="text-capitalize">{{ detail.priority }}</span>
            </v-chip>
          </template>
          <span>Priority</span>
        </v-tooltip>
        <v-chip
          style="height: 25px"
          :color="getTextColor(detail.status)"
          label
          small
          class="text-white"
        >
          <span class="text-capitalize">{{
            getStatusText(detail.status)
          }}</span>
        </v-chip>
      </div>
    </template>

    <template v-slot:header-action>
      <div class="d-flex align-center justify-end flex-wrap" v-if="pageLoaded">
        <v-menu
          offset-y
          left
          bottom
          content-class="elevation-3"
          rounded="0"
          v-if="detail.status != 5"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="cyan text-white"
              v-on="on"
              v-bind="attrs"
              class="mr-2"
              depressed
              tile
              ><v-icon small left>mdi-more mdi-flip-h</v-icon>More</v-btn
            >
          </template>

          <v-list>
            <v-list-item
              v-on:click="updateStatus('in-progress', detail.id, 'status')"
              v-if="detail.status != 2 || detail.status != 5"
            >
              <v-list-item-title>
                <v-chip
                  style="height: 16px; width: 16px; padding: 0"
                  color="red"
                >
                </v-chip>
                Mark As In-Progress
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-on:click="confirm_dialog = true"
              v-if="detail.status != 5"
            >
              <v-list-item-title>
                <v-chip
                  style="height: 16px; width: 16px; padding: 0"
                  color="green"
                >
                </v-chip>
                Mark As Closed
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <template>
          <v-btn
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-on:click="routeToUpdate()"
          >
            <v-icon left>mdi-pencil</v-icon>
            Edit
          </v-btn>
        </template>
        <v-btn
          class="mx-2 mb-1 custom-grey-border custom-bold-button"
          v-on:click="goBack()"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="height: calc(100vh - 110px); position: relative"
      >
        <v-container fluid>
          <v-row v-if="pageLoaded">
            <v-col md="6">
              <span class="color-custom-blue font-weight-700 font-size-19">
                <router-link
                  :to="
                    getDefaultRoute('customer.detail', {
                      params: {
                        id: detail.customer.id,
                      },
                    })
                  "
                  >{{ detail.customer.display_name }}
                </router-link>
              </span>
              <v-layout class="my-4">
                <v-flex md6 class="mr-2 custom-border-right">
                  <table width="100%">
                    <tr>
                      <td class="font-weight-600">Service Location</td>
                    </tr>
                    <tr
                      v-if="
                        detail &&
                        detail.property &&
                        detail.property.property_address
                      "
                    >
                      <td class="py-0 font-size-18 font-weight-500">
                        <a
                          target="_blank"
                          :href="`https://maps.google.com/?q=${detail.property.property_address}`"
                          >{{ detail.property.property_address }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">Contact details</td>
                    </tr>
                    <tr v-if="detail && detail.property_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            detail &&
                            detail.property_person &&
                            detail.property_person.display_name
                          "
                        >
                          {{ detail.property_person.display_name }}
                        </template>
                      </td>
                    </tr>
                    <tr v-if="detail.property_person.primary_phone">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            detail &&
                            detail.property_person &&
                            detail.property_person.primary_phone
                          "
                        >
                          {{ detail.property_person.primary_phone }}
                        </template>
                      </td>
                    </tr>
                    <tr v-if="detail.property_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            detail &&
                            detail.property_person &&
                            detail.property_person.primary_email
                          "
                        >
                          {{ detail.property_person.primary_email }}
                        </template>
                      </td>
                    </tr>
                  </table>
                </v-flex>
                <v-flex md6 class="ml-2">
                  <table width="100%">
                    <tr>
                      <td class="font-weight-600">
                        Billing Address
                        <!-- <pre>{{serviceformdata.length}}</pre> -->
                      </td>
                    </tr>
                    <tr
                      v-if="
                        detail &&
                        detail.billing &&
                        detail.billing.property_address
                      "
                    >
                      <td class="py-0 font-size-18 font-weight-500">
                        <a
                          target="_blank"
                          :href="`https://maps.google.com/?q=${detail.billing.property_address}`"
                          >{{ detail.billing.property_address }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">Contact details</td>
                    </tr>
                    <tr v-if="detail && detail.billing_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            detail &&
                            detail.billing_person &&
                            detail.billing_person.display_name
                          "
                        >
                          {{ detail.billing_person.display_name }}
                        </template>
                      </td>
                    </tr>
                    <tr v-if="detail.billing_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            detail &&
                            detail.billing_person &&
                            detail.billing_person.primary_phone
                          "
                        >
                          {{ detail.billing_person.primary_phone }}
                        </template>
                      </td>
                    </tr>
                    <tr v-if="detail.billing_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            detail &&
                            detail.billing_person &&
                            detail.billing_person.primary_email
                          "
                        >
                          {{ detail.billing_person.primary_email }}
                        </template>
                        <!-- <v-icon
                            v-if="detail.customer.billing"
                            v-tippy="{
                              arrow: true,
                              arrowType: 'round',
                              animation: 'fade',
                            }"
                            content="Notification was sent to given email"
                            color="green lighten-1"
                            >mdi-check</v-icon
                          > -->
                      </td>
                    </tr>
                  </table>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col md="6" class="custom-border-left gray-background">
              <table width="100%">
                <!--  <tr>
                    <td valign="middle" class="py-1 font-size-18 font-weight-600">
                      Incident #
                    </td>
                    <td valign="middle" class="py-1 font-size-18 font-weight-500">
                      <label class="m-0">{{ detail.barcode }}</label>
                    </td>
                  </tr> -->

                <tr>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-600"
                    style="width: 120px !important"
                  >
                    Impact
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label v-if="detail && detail.impact" class="m-0">
                      <v-chip
                        small
                        :color="impactColor(detail.impact)"
                        label
                        class=""
                        outlined
                      >
                        <span
                          class="font-size-16 font-weight-500 text-capitalize"
                        >
                          {{ detail.impact }}
                        </span>
                      </v-chip>
                    </label>
                    <em v-else class="text-muted">no impact</em>
                  </td>
                </tr>

                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Tags
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <v-chip
                      v-for="(row, index) in detail.tags"
                      :key="index"
                      style="height: 26px"
                      class="mr-2"
                      color="cyan white--text"
                      >{{ row }}</v-chip
                    >
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Assign By
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <span
                      v-if="detail && detail.user && detail.user.full_name"
                      >{{ detail.user.full_name }}</span
                    >
                    <em v-else class="text-muted"> no assign</em>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Due Date
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <span v-if="detail && detail.due_date">
                      <v-chip
                        small
                        color="red white--text"
                        label
                        class=""
                        outlined
                      >
                        <span class="font-size-16 font-weight-500">
                          {{ formatDate(detail.due_date) }}
                          {{ detail.due_time }}</span
                        >
                      </v-chip>
                    </span>
                    <em v-else class="text-muted">no date</em>
                  </td>
                </tr>
                <!-- <tr>
                    <td valign="middle" class="py-1 font-size-18 font-weight-600 d-flex">
                        Remark
                    </td>
                    <td valign="middle" class="py-1 font-size-18 font-weight-500">
                        <span v-if="detail && detail.remark">{{ detail.remark }}</span>
                        <em v-else class="text-muted">no remark</em>
                    </td>
                  </tr>
                  <tr v-if="detail && detail.closer_remark">
                    <td valign="middle" class="py-1 font-size-18 font-weight-600 d-flex">
                        Closer Remark
                    </td>
                    <td valign="middle" class="py-1 font-size-18 font-weight-500">
                        <span v-if="detail && detail.closer_remark">{{ detail.closer_remark }}</span>
                        <em v-else class="text-muted">no remark</em>
                    </td>
                  </tr> -->
              </table>
            </v-col>
            <v-col md="12">
              <v-tabs
                active-class="custom-tab-active"
                v-model="ticketTab"
                background-color="transparent"
                color="cyan"
                class="custom-tab-transparent tab-sticky"
              >
                <v-tab class="font-size-16 font-weight-600 px-8" href="#notes">
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/visit.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Notes
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#overView"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/visit.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Overview
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="ticketTab">
                <v-tab-item value="notes">
                  <ChatNote
                    :relatedId="incidentId"
                    :relatedType="'incident-report'"
                    :documentTypes="27"
                    create-url="incident-report"
                    get-url="incident-report"
                    :detail="detail"
                    :visit="visit"
                  ></ChatNote>
                </v-tab-item>
                <v-tab-item value="overView">
                  <!-- <v-expansion-panels v-model="panel" inset>
                        <v-expansion-panel>
                        <v-expansion-panel-header
                          ><h2 class="margin-auto color-custom-blue font-weight-600">
                            Overview
                          </h2></v-expansion-panel-header
                        >
                        <v-expansion-panel-content> -->
                  <IncidentOverview :overview="detail"></IncidentOverview>
                  <!--  </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels> -->
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
      </perfect-scrollbar>
      <Dialog :common-dialog="confirm_dialog" :dialog-width="dialogWidth">
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
            <v-container class="px-10" fluid>
              <p class="font-weight-600 font-size-19">
                Are you sure, you want to
                <span class="font-weight-700">Closed</span>?
              </p>
              <v-layout class="my-4">
                <v-flex>
                  <label class="font-weight-500 font-size-16 required"
                    >Closer Remark</label
                  >
                  <v-textarea
                    v-model="closerRemark"
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    label="Closer Remark"
                    solo
                    flat
                    row-height="25"
                    v-on:keypress="(e) => manageLimit(e)"
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4">
                <v-flex md12>
                  {{ closerRemark ? closerRemark.length : 0 }}/200
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="confirm_loading"
            v-on:click="confirm_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            No! Close
          </v-btn>
          <v-btn
            v-on:click="updateStatusRemark()"
            :disabled="!formValid || confirm_loading"
            :loading="confirm_loading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Yes! Continue
          </v-btn>
        </template>
      </Dialog>
    </template>
  </DetailTemplate>
</template>
<script>
import moment from "moment-timezone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import IncidentOverview from "@/view/pages/incident/Incident-overview.vue";

import CommonMixin from "@/core/plugins/common-mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
/*   import FileTemplate from "@/view/pages/partials/FileTemplate.vue"; */
import ChatNote from "@/view/pages/partials/ChatNote";
import ValidationMixin from "@/core/plugins/validation-mixin";

import Dialog from "@/view/pages/partials/Dialog";
import { GET, PATCH } from "@/core/services/store/request.module";

import JobRequestMixin from "@/core/lib/job/api.request.job.mixin";

import { toSafeInteger } from "lodash";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { currentUser } from "@/core/services/jwt.service";
/*   import ObjectPath from "object-path"; */
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin, JobRequestMixin],
  data() {
    return {
      closerRemark: null,
      panel: 0,
      incidentId: 0,
      yourFormat: "hh:mm A",
      ticketId: 0,
      share_dialog: false,
      cancel_reason: null,
      pageLoading: false,
      pageLoaded: false,
      statusLoading: false,
      formValid: true,
      apptFormLoading: false,
      apptEngLoading: false,
      engineer_menu: false,
      ticketTab: "other",
      timerServiceForm: [],
      confirm_loading: false,
      serviceformdata: [],
      moreActions: [],
      detail: {
        term_conditions: null,
      },
      visit: {},
      paymentmodes: {},
      company_email: {},
      dbLineItems: [],
      dbEquipments: [],
      engineerList: [],
      assigned_team: [],
      closerdata: [],
      confirm_dialog: false,
    };
  },
  methods: {
    priorityColor(data) {
      if (data == "high") {
        return "red darken-3";
      }
      if (data == "medium") {
        return "orange darken-4";
      }
      if (data == "low") {
        return "light-green darken-3";
      }
      if (data == 0) {
        return "cyan";
      }
      return null;
    },
    impactColor(data) {
      if (data == "Highest") {
        return "red darken-3";
      }
      if (data == "Medium") {
        return "orange darken-4";
      }
      if (data == "Lowest") {
        return "light-green darken-3";
      }
      if (data == 0) {
        return "cyan";
      }
      return null;
    },
    manageLimit(e) {
      if (this.closerRemark && this.closerRemark.length > 199) {
        e.preventDefault();
      }
    },
    updateStatusRemark() {
      this.$store
        .dispatch(PATCH, {
          url: `update-status-report/${this.detail.id}`,
          data: {
            status: "closed",
            remark: this.closerRemark,
          },
        })
        .then(() => {
          this.confirm_dialog = false;
          this.getVisit();
        })
        .catch((error) => {
          console.log({ error });
        });
    },
    getVisit() {
      this.$store
        .dispatch(GET, { url: "incident-report/" + this.incidentId })
        .then(({ data }) => {
          this.detail = data;
          this.$nextTick(() => {});

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Incident Report", route: "visit" },
            { title: "Detail" },
            { title: this.visit.barcode },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
        });
    },
    getStatusText(data) {
      if (data == 1) {
        return "New";
      }
      if (data == 2) {
        return "In-Progress";
      }
      if (data == 5) {
        return "Closed";
      }
    },
    getTextColor(data) {
      if (data == 1) {
        return "blue";
      }
      if (data == 2) {
        return "red";
      }
      if (data == 5) {
        return "green";
      }
    },
    updateStatus(value, id, type) {
      var typeKey = type;
      this.$store
        .dispatch(PATCH, {
          url: `update-status-report/${id}`,
          data: {
            [typeKey]: value,
          },
        })
        .then(() => {
          this.getVisit();
        })
        .catch((error) => {
          console.log({ error });
        });
    },
    /*  routeToUpdate() {
        this.$router.push(
          this.getDefaultRoute("incident.update", {
            params: {
            id: ObjectPath.get(this.detail, "id", 0),
          },
            query: {
              customer: ObjectPath.get(this.detail, "customer.id", null), // customer ? customer.id : null
              property: ObjectPath.get(this.detail, "property.id", null),
            },
          })
        );
      }, */
    routeToUpdate() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("incident.update", {
          params: {
            id: this.incidentId,
          },
          query: {
            customer: customer ? customer.id : null,
            billing: billing ? billing.id : null,
            property: property ? property.id : null,
            property_person: property_person ? property_person.id : null,
            billing_person: billing_person ? billing_person.id : null,
          },
        })
      );
    },
  },
  created() {
    this.incidentId = toSafeInteger(this.$route.params.id);
  },
  mounted() {
    this.getVisit();

    ServiceFormEventBus.$on("refresh", () => {
      // this.getAmount();
    });
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
    clearInterval(this.durationInterval);
  },
  computed: {
    is_staff() {
      const user = currentUser();
      const role_name = this.lodash.toLower(user.role_name);
      return role_name == "admin";
    },
    is_staff_login() {
      const user = currentUser();
      const role_engineer = this.lodash.toLower(user.engineer);

      return role_engineer == role_engineer;
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 40);
    },
  },
  components: {
    Dialog,
    /*  Dialog, */
    /*  Priority, */
    /*  CreateCloser, */
    /*  CustomStatus, */
    /*  FileTemplate, */
    DetailTemplate,
    /*   Datepicker, */
    /* Barcode, */
    ChatNote,
    IncidentOverview,
    // Timepicker,
    /*  InternalInvoice,
      InternalQuotation, */
    /*  TicketLineItem,
      VisitTeamDetail,
      VisitTimestamp,
      ServiceFormDetail,
      TicketCloserDetail,
      VisitNotesDetail, */
    /*   SendMail, */
    /*   ShareServiceReport, */
    // SelectTeam,
    /*   InternalHistoryDetail, */
    /* VueTimepicker, */
    /*   VisitfollowupDetail, */
    /* VisitListingTemplate, */
  },
};
</script>
